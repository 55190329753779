export default {
  header: {
    nav: [
      { name: "首頁", path: "/home" },
      { name: "聯系我們", path: "/contact" },
      { name: "關於清科", path: "/aboutQk" },
    ],
  },
  footer: {
    address: "香港九龍柯士甸道西1號 環球貿易廣場15樓1506B室",
    onlineService: "在線客服",
    disclaimer: "免責申明",
    privacy: "隱私策略",
    qkInfoTop: "清科國際控股有限公司 版權所有，違者必究",
    qkInfoBottom:
      "Copyright &#169; 2021 Zero2IPO International Holdings Limited. All Rights Reserved.",
  },
  slideBar: {
    deal: "在線交易",
    open: "開戶指引",
    down: "軟件下載",
    phone: "聯系我們",
  },
};
