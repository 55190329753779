export default {
  clickWatch: "點擊查看>>",
  personTitle: "個人客戶開戶",
  personDesc:
    "掃碼下載APP，在線填寫開戶信息，即刻獲得開戶服務。APP在線開戶指引請",
  mechanismTitle: "機構客戶開戶",
  mechanismDesc: "機構客戶開戶指引請",
  clickDown: "點擊下載",
  bottomDesc:
    "如需獲得更為便捷的開戶體驗，敬請下載清科證券APP在線填寫開戶信息，即刻獲得開戶服務。",
};
