<template>
  <div id="app">
    <router-view :key="refresh" />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {
    refresh() {
      return this.$i18n.locale || "zh";
    },
  },
};
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
