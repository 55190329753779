export default {
  advantageTitle: "我們的優勢",
  advantageList: [
    {
      imgUrl: require("@/assets/aboutQk/bt-1.png"),
      title: "經驗豐富",
    },
    {
      imgUrl: require("@/assets/aboutQk/bt-2.png"),
      title: "善於學習",
    },
    {
      imgUrl: require("@/assets/aboutQk/bt-3.png"),
      title: "技術過硬",
    },
    {
      imgUrl: require("@/assets/aboutQk/bt-4.png"),
      title: "質量可靠",
    },
    {
      imgUrl: require("@/assets/aboutQk/bt-5.png"),
      title: "工匠精神",
    },
    {
      imgUrl: require("@/assets/aboutQk/bt-6.png"),
      title: "服務意識",
    },
  ],
};
