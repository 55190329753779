export default {
  advantageList: [
    {
      imgSrc: require("@/assets/home/list1.png"),
      title: "需求分析能力",
      desc: "专业、高效的需求分析",
      liList: [
        {
          iconSrc: require("@/assets/home/list1-1.png"),
          text: "善于高效学习",
        },
        {
          iconSrc: require("@/assets/home/list1-2.png"),
          text: "敬业是基本要求",
        },
        {
          iconSrc: require("@/assets/home/list1-3.png"),
          text: "做“懂你”的合作方",
        },
      ],
    },
    {
      imgSrc: require("@/assets/home/list2.png"),
      title: "软件开发能力",
      desc: "经验丰富，严谨负责",
      liList: [
        {
          iconSrc: require("@/assets/home/list2-1.png"),
          text: "规范的开发流程",
        },
        {
          iconSrc: require("@/assets/home/list2-2.png"),
          text: "过硬的技术能力",
        },
        {
          iconSrc: require("@/assets/home/list2-3.png"),
          text: "进度量化、可控",
        },
      ],
    },

    {
      imgSrc: require("@/assets/home/list3.png"),
      title: "软件测试能力",
      desc: "严格的质量管理规范",
      liList: [
        {
          iconSrc: require("@/assets/home/list3-1.png"),
          text: "功能测试和性能测试并重",
        },
        {
          iconSrc: require("@/assets/home/list3-2.png"),
          text: "人工测试和自动化测试同步",
        },
        {
          iconSrc: require("@/assets/home/list3-3.png"),
          text: "需求、开发、交付全程质量跟踪",
        },
      ],
    },
    {
      imgSrc: require("@/assets/home/list4.png"),
      title: "项目交付能力",
      desc: "交付不是终结",
      liList: [
        {
          iconSrc: require("@/assets/home/list4-1.png"),
          text: "现场服务",
        },
        {
          iconSrc: require("@/assets/home/list4-2.png"),
          text: "贴心培训",
        },
        {
          iconSrc: require("@/assets/home/list4-3.png"),
          text: "成功交付是项目的新阶段",
        },
      ],
    },
  ],
  qkMember: "清科集团成员链接",
};
