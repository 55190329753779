import innerLayout from "@/lang/moudle/inner-layout/cn";
import common from "@/lang/moudle/common/cn";
import home from "@/lang/moudle/home/cn";
import contact from "@/lang/moudle/contact/cn";
import aboutQk from "@/lang/moudle/aboutQk/cn";

export default {
  innerLayout,
  common,
  home,
  contact,
  aboutQk,
};
