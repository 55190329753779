import Vue from "vue";
import VueRouter from "vue-router";
const InnerLayout = () =>
  import(/* webpackChunkName:'innerLayout' */ "@/layouts/inner-layout");
// 首页
const Home = () => import(/* webpackChunkName:'Home' */ "@/views/home");
//联系我们
const Case = () => import(/* webpackChunkName:'Contact' */ "@/views/case");
//联系我们
const Contact = () =>
  import(/* webpackChunkName:'Contact' */ "@/views/contact");
//关于清科
const AboutQk = () =>
  import(/* webpackChunkName:'AboutQk' */ "@/views/aboutQk");

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const routes = [
  {
    path: "/",
    name: "InnerLayout",
    component: InnerLayout,
    meta: {},
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "Home",
        component: Home,
        meta: {
          title: "首页",
        },
      },
      {
        path: "/case",
        name: "Case",
        component: Case,
        meta: {
          title: "精品案例",
        },
      },
      {
        path: "/contact",
        name: "Contact",
        component: Contact,
        meta: {
          title: "联系我们",
        },
      },
      {
        path: "/aboutQk",
        name: "AboutQk",
        component: AboutQk,
        meta: {
          title: "关于清科",
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  routes,
  scrollBehavior(to, from, savedPosition) {
    // keep-alive 返回缓存页面后记录浏览位置
    if (savedPosition && to.meta.keepAlive) {
      return savedPosition;
    } // 异步滚动操作
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ x: 0, y: 1 });
      }, 0);
    });
  },
});

export default router;
