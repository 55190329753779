export default {
  header: {
    nav: [
      { name: "首页", path: "/home" },
      { name: "精品案例", path: "/case" },
      { name: "关于我们", path: "/aboutQk" },
      { name: "联系我们", path: "/contact" },
    ],
  },
  footer: {
    address: "香港九龙柯士甸道西1号 环球贸易广场15楼1506B室",
    onlineService: "在线客服",
    disclaimer: "免责申明",
    privacy: "隐私策略",
    qkInfoTop: "清科国际控股有限公司 版权所有，违者必究",
    qkInfoBottom:
      "Copyright &#169; 2021 Zero2IPO International Holdings Limited. All Rights Reserved.",
  },
  slideBar: {
    deal: "在线交易",
    open: "开户指引",
    down: "软件下载",
    phone: "联系我们",
  },
};
