export default {
  advantageTitle: "我们的优势",
  advantageList: [
    {
      imgUrl: require("@/assets/aboutQk/bt-1.png"),
      title: "经验丰富",
    },
    {
      imgUrl: require("@/assets/aboutQk/bt-2.png"),
      title: "善于学习",
    },
    {
      imgUrl: require("@/assets/aboutQk/bt-3.png"),
      title: "技术过硬",
    },
    {
      imgUrl: require("@/assets/aboutQk/bt-4.png"),
      title: "质量可靠",
    },
    {
      imgUrl: require("@/assets/aboutQk/bt-5.png"),
      title: "工匠精神",
    },
    {
      imgUrl: require("@/assets/aboutQk/bt-6.png"),
      title: "服务意识",
    },
  ],
};
