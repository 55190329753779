import Vue from "vue"; // 引入vue
import VueI18n from "vue-i18n"; // 引入i18n模块
import elementHKLocale from "element-ui/lib/locale/lang/zh-TW"; // element-ui英文包
import elementZhLocale from "element-ui/lib/locale/lang/zh-CN"; // element-ui中文包
import locale from "element-ui/lib/locale"; // 引入element-ui语言包模块
import hkLocale from "./zh-HK"; // 本地英文包
import zhLocale from "./zh-CN"; // 本地中文包
// import { getCookie } from "@/utils/cookie";

Vue.use(VueI18n); // vue使用i18n模块
// 引入本地
const messages = {
  hk: {
    ...hkLocale, // es6的拓展运算符，相当于解析出每个对象
    ...elementHKLocale,
  },
  zh: {
    ...zhLocale,
    ...elementZhLocale,
  },
};
// 创建国际化实例
const i18n = new VueI18n({
  locale: sessionStorage.getItem("language") || "zh", // set locale
  // locale: getCookie("PLAY_LANG", "zh"),
  messages, // set locale messages
});
locale.i18n((key, value) => i18n.t(key, value));

export default i18n;
