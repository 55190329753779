import innerLayout from "@/lang/moudle/inner-layout/hk";
import common from "@/lang/moudle/common/hk";
import home from "@/lang/moudle/home/hk";
import contact from "@/lang/moudle/contact/hk";
import aboutQk from "@/lang/moudle/aboutQk/hk";

export default {
  innerLayout,
  common,
  home,
  contact,
  aboutQk,
};
